<template>
  <a-button type="primary" shape="round" :size="size"
  @click="fileDownloader"
  >
    <template #icon>
      <DownloadOutlined />
      {{ downloadButtonTitle }}
    </template>
  </a-button>
</template>
<script>
// 提供链接，执行下载文件
import { DownloadOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref } from 'vue';
import {fileHandler} from '@/utils/request.js'
import {message} from 'ant-design-vue'
import {useStore} from 'vuex'
export default defineComponent({
  props: ['downloadButtonTitle', 'targetUrl'],
  components: {
    DownloadOutlined,
  },

  setup(props) {
    const store = useStore()
    const onFileHandlerError = (e) => {
        message.error(e+'下载失败！')
    }
    const fileDownloader = () => {
      fileHandler(props.targetUrl, "JWT " + store.state.user_login_auth.user["token"], onFileHandlerError)
    }
    return {
      size: ref('large'),
      fileDownloader
    };
  },
});
</script>