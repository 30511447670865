<template>
  <a-select
    show-search
    v-model:value="value"
    :placeholder="searchInputTitle"
    style="width: 200px"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="null"
    @search="handleSearch"
    @change="handleChange"
  >
    <a-select-option v-for="d in data" :key="d">
      {{ d }}
    </a-select-option>
  </a-select>
</template>
<script>
// 可以通过搜索获取值，并选择值
import {request} from '@/utils/request.js'
import { defineComponent, ref, getCurrentInstance } from 'vue';
import {useStore} from 'vuex'
import {message} from 'ant-design-vue'
export default defineComponent({
props: ['searchInputTitle', 'targetUrl', 'onValueSelected'],
  setup(props) {
    const store = useStore()
    const timeout = ref()
    // 获取全局属性的方法
    const properties = getCurrentInstance()
    const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
    const data = ref([]);
    const value = ref();
    const getData = (v) => {
      data.value = []
      if (timeout.value) {
    clearTimeout(timeout.value);
    timeout.value = null;
  }
    const exec = () => {
      data.value = []
      request({
        // 获取全局属性的方法
        url: baseUrl+props.targetUrl +v+'/',
        method: "get",
        headers: {
          Authorization: "JWT " + store.state.user_login_auth.user["token"],
        },
      }
    ).then(d => {
          for (var r in d.data.results) {
            data.value.push(d.data.results[r]);
          };
      });
    }
    timeout.value = setTimeout(exec, 300);
}
    const handleSearch = val => {
      if (props.targetUrl) {
      getData(val);
      } else {
        message.error('未生成原料搜索链接！')
      }
    };

    const handleChange = val => {
      props.onValueSelected(val)
      value.value = val;
      getData(val);
    };

    return {
      handleSearch,
      handleChange,
      data,
      value,
    };
  },
});
</script>