<template>
  <a-table :columns="$store.state.ingredients_status.allIngredientsColumn['columns']" 
  :data-source="dataSource">
    <template #expandedRowRender="{record}">
     <DetailIngredientDescriber :ingredientName="record['原料']"
     :detailData="record"
     />
     <a-divider style="border-color: #7cb305" dashed />
     <RelatedProductsAndConsume :ingredient="record['原料']" />
    </template>
  </a-table>
</template>
<script>
// 所有文件状态的表格
import { defineComponent, ref, getCurrentInstance, watch } from 'vue';
import DetailIngredientDescriber from '@/components/ingredients_status/DetailIngredientDescriber.vue'
import RelatedProductsAndConsume from '@/components/ingredients_status/RelatedProductsAndConsume.vue'
import {request} from '@/utils/request.js'
import {useStore} from 'vuex'
export default defineComponent({
  components:{
DetailIngredientDescriber,
RelatedProductsAndConsume
  },
  setup() {
    const store = useStore()
    const showTableColumnsUrl = ref('')
    const showTableDataUrl = ref('')
    const dataSource = ref([])
    const properties = getCurrentInstance()
    const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
    const getConlumns = () => {
      request(
        {
          url: showTableColumnsUrl.value,
          method: "get",
          headers: {
                  Authorization: "JWT " + store.state.user_login_auth.user["token"],
                  },
        }
      ).then(response => {
        store.commit(
                {
                    type: "ingredients_status/saveAllIngredientsColumn",
                    payload: response.data
                }
            )
      })
    }
    const getData = () => {
      request(
        {
          url: showTableDataUrl.value,
          method: "get",
          headers: {
                  Authorization: "JWT " + store.state.user_login_auth.user["token"],
                  },
        }
      ).then(response => {
        store.commit(
                {
                    type: "ingredients_status/saveAllIngredientsData",
                    payload: response.data
                }
            )
      })
    }
    watch(() => store.state.ingredients_status.selectedOrg , function() {
      showTableDataUrl.value=''
            if (store.state.ingredients_status.selectedOrg['org'] && store.state.ingredients_status.selectedDate['date']) {
                const date = store.state.ingredients_status.selectedDate['date'].split('-')
                showTableColumnsUrl.value = `${baseUrl}/v1/mainapp/ingredients_consume_daily_report/columns/${date[0]}/${date[1]}/${store.state.ingredients_status.selectedDate['date']}/${store.state.ingredients_status.selectedOrg['org']}/`
            }
        })
    watch(() => store.state.ingredients_status.selectedDate , function() {
      showTableDataUrl.value=''
        if (store.state.ingredients_status.selectedOrg['org'] && store.state.ingredients_status.selectedDate['date']) {
             const date = store.state.ingredients_status.selectedDate['date'].split('-')
                showTableColumnsUrl.value = `${baseUrl}/v1/mainapp/ingredients_consume_daily_report/columns/${date[0]}/${date[1]}/${store.state.ingredients_status.selectedDate['date']}/${store.state.ingredients_status.selectedOrg['org']}/`
        }
    })
    watch(() => showTableColumnsUrl.value, function() {
      if (showTableColumnsUrl.value) {
        getConlumns()
        store.commit(
                {
                    type: "ingredients_status/saveAllIngredientsData",
                    payload: {}
                }
            )
        showTableDataUrl.value = showTableColumnsUrl.value.split('columns')[0] + 'data' + showTableColumnsUrl.value.split('columns')[1]
        getData()
      }
    })
    watch(() => store.state.ingredients_status.selectedIngredient, function() {
      if (store.state.ingredients_status.selectedIngredient['ingredient']) {
        var selectedData = []
        for (var d in store.state.ingredients_status.allIngredientsData['data']) {
          if (store.state.ingredients_status.allIngredientsData['data'][d]['原料'] === store.state.ingredients_status.selectedIngredient['ingredient']) {
            selectedData.push(store.state.ingredients_status.allIngredientsData['data'][d])
          }
        }
        dataSource.value = selectedData
      }
      else {
        dataSource.value = store.state.ingredients_status.allIngredientsData['data']
      }
    })
    watch(() => store.state.ingredients_status.allIngredientsData, function() {
        store.commit(
                {
                    type: "ingredients_status/saveSelectedIngredient",
                    payload: {'ingredient':''}
                }
            )
        dataSource.value = store.state.ingredients_status.allIngredientsData['data']
      }
    )
    return {
      dataSource,
      showTableDataUrl
    };
  },
});
</script>