<template>
  <a-descriptions :title="ingredientName">
    <a-descriptions-item v-for="v, k in detailData" :label="k" :key="k">{{v}}</a-descriptions-item>
  </a-descriptions>
</template>

<script>
// 具体原料的描述信息，点击DescribeAllIngredients中的+，可以展开
import { defineComponent } from 'vue'

export default defineComponent({
  props:['ingredientName', 'detailData'],
  setup() {
    
  },
})
</script>
