<template>
<a-typography-text strong>选择产品</a-typography-text>
<a style="margin-left: 10px"
@click="getProductData">点我获取{{ ingredient }}相关产品数据</a>
  <a-select
    mode="multiple"
    v-model:value="value"
    style="width: 100%"
    placeholder="等待产品名称出现后选择产品"
    @change="handleChange"
  >
    <a-select-option v-for="i in relatedProduct" :key="i">
      {{ i }}
    </a-select-option>
  </a-select>
  <a-typography-text strong>平均总共可生产上述产品：{{ ingredientOutputProductAverage }}</a-typography-text>
</template>
<script>
import { defineComponent, ref, getCurrentInstance } from 'vue';
import {request} from '@/utils/request.js'
import {useStore} from 'vuex'
export default defineComponent({
  props: ['ingredient'],
  setup(props) {
    const store = useStore()
    const timeout = ref()
    const relatedProduct = ref([])
    const properties = getCurrentInstance()
    const ingredientOutputProductAverage = ref(0)
    const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
    const execUrl = ref('')
    const handleChange = value => {
      if (timeout.value) {
    clearTimeout(timeout.value);
    timeout.value = null;
  }
    const exec = () => {
      var products = ''
      for (var pdt in value) {
        products = products + `=${value[pdt]}`
      }
      console.log(products)
      const date = store.state.ingredients_status.selectedDate['date'].split('-')
      execUrl.value = encodeURI(`${baseUrl}/v1/mainapp/ingredients_consume_daily_report/ingredient_output_products=${props.ingredient}${products}/${date[0]}/${date[1]}/${store.state.ingredients_status.selectedDate['date']}/${store.state.ingredients_status.selectedOrg['org']}/`)
      if (products && execUrl.value) {
        request(
        {
          url: execUrl.value,
          method: "get",
          headers: {
                  Authorization: "JWT " + store.state.user_login_auth.user["token"],
                  },
        }
      ).then(
        response => {
          console.log(response.data)
          ingredientOutputProductAverage.value = response.data
        }
      )
    }
      }
    timeout.value = setTimeout(exec, 300);

    };
    const getProductData = () => {
      request({
        url: encodeURI(baseUrl+'/v1/basic_data_manager/products_meta_data/ingredient_related_product=' + props.ingredient + '=' + store.state.ingredients_status.selectedOrg['org'] + '/'),
          method: "get",
          headers: {
                  Authorization: "JWT " + store.state.user_login_auth.user["token"],
                  },
      }).then(response => {
        relatedProduct.value = response.data
      })
    }
    return {
      handleChange,
      value: ref([]),
      relatedProduct,
      getProductData,
      ingredientOutputProductAverage
    };
  },
});
</script>