<template>
    <div>
        <a-typography-text strong>选择工厂和日期：</a-typography-text>
        <a-select label-in-value v-model:value="selectedOrg" style="width: 300px; margin-right: 10px" @change="handleOrgChange">
    <a-select-option v-for="k in outputOrg" :value="k" :key="k">{{ k }}</a-select-option>
  </a-select>
        <a-month-picker v-model:value="selectedDate" style="margin-bottom: 8px" 
        @change="handleDateChange"
        />
        <br />
        <a-typography-text strong>搜索并选择原料：</a-typography-text><GetSearchValueInput style="margin-top: 2px"
        searchInputTitle="请输入关键字搜索原料"
        :targetUrl="ingredientsSearchUrl"
        :onValueSelected="handleIngredientSelected"
        />
        <a-divider style="border-color: #7cb305" dashed />
        <DownloadFile downloadButtonTitle="月前预测原料耗用报表" :targetUrl="beforeForecastUrl" />
        <DownloadFile downloadButtonTitle="本月预测原料耗用报表" style="margin-left: 10px" :targetUrl="thisMothForecastUrl" />
 <a-divider style="border-color: #7cb305" dashed />
 <a-typography-title :level="3">原料使用状态概览（单位：kg）</a-typography-title>
 <a-divider style="border-color: #7cb305" dashed />
      <DescribeAllIngredients />
    </div>
</template>

<script>
import DescribeAllIngredients from '@/components/ingredients_status/DescribeAllIngredients.vue'
import DownloadFile from '@/components/public/DownloadFile.vue'
import GetSearchValueInput from '@/components/public/GetSearchValueInput.vue'
import {request} from '@/utils/request.js'
import {useStore} from 'vuex'
import {defineComponent, onMounted, ref, getCurrentInstance, watch} from 'vue'
export default defineComponent({
    components: {
        DescribeAllIngredients,
        DownloadFile,
        GetSearchValueInput,
    },
    setup(){
        const selectedDate = ref()
        const store = useStore()
        const properties = getCurrentInstance()
        const selectedOrg = ref('')
        const outputOrg = ref()
        const ingredientsSearchUrl = ref('')
        const beforeForecastUrl = ref('')
        const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
        onMounted(() => {
            request({
                url: baseUrl+'/v1/basic_data_manager/orgnization_meta_data/top_grade_output_org/',
                method: "get",
                headers: {
                Authorization: "JWT " + store.state.user_login_auth.user["token"],
                },
            }).then(response => {
                outputOrg.value = response.data
            })
        })
        const handleOrgChange = () => {
            store.commit(
                {
                    type: "ingredients_status/saveSelectedOrg",
                    payload: {'org' : selectedOrg.value.value}
                }
            )
        }
        const handleDateChange = () => {
            store.commit(
                {
                    type: "ingredients_status/saveSelectedDate",
                    payload: {'date':selectedDate.value.format('YYYY-MM')}
                }
            )

        }
        const handleIngredientSelected = (val) => {
            store.commit(
                {
                    type: "ingredients_status/saveSelectedIngredient",
                    payload: {'ingredient':val}
                }
            )
        }
        watch(() => store.state.ingredients_status.selectedOrg , function() {
            if (store.state.ingredients_status.selectedOrg['org']) {
                ingredientsSearchUrl.value = `/v1/basic_data_manager/ingredients_meta_data/ingredients_in_use_for_search_input/${store.state.ingredients_status.selectedOrg['org']}/`
                if (store.state.ingredients_status.selectedDate['date']) {
                    const date = store.state.ingredients_status.selectedDate['date'].split('-')
                    beforeForecastUrl.value = `${baseUrl}/v1/mainapp/generate_ingredients_total_consume_forecast_by_leader/by_date/${date[0]}/${date[1]}/${store.state.ingredients_status.selectedOrg['org']}/`
                }
            }
        })
        watch(() => store.state.ingredients_status.selectedDate , function() {
            if (store.state.ingredients_status.selectedDate['date']) {
                const date = store.state.ingredients_status.selectedDate['date'].split('-')
                beforeForecastUrl.value = `${baseUrl}/v1/mainapp/generate_ingredients_total_consume_forecast_by_leader/by_date/${date[0]}/${date[1]}/${store.state.ingredients_status.selectedOrg['org']}/`
            }
        })
        return {
            selectedDate,
            selectedOrg,
            outputOrg,
            ingredientsSearchUrl,
            beforeForecastUrl,
            handleOrgChange,
            handleDateChange,
            handleIngredientSelected,
        }
    }

})
</script>

<style>

</style>